import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';
import {  } from '@aws-amplify/ui-react';
import { useState } from 'react';

import './App.css';

Amplify.configure({
  Auth: {
    Cognito: {
      loginWith: {
        oauth: {
          domain: import.meta.env.VITE_COGNITO_DOMAIN,
          scopes: [
            'email',
            'openid',
          ],
          redirectSignIn: [window.location.origin],
          redirectSignOut: [window.location.origin],
          responseType: 'code',
        },
      },
      userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
    },
  },
});

const authenticatorComponents = {
  SignIn: {
    Footer() {
      return null;
    },
  },
};

export function App() {
  const [state, setState] = useState<'' | 'error' | 'loading' | 'success'>('');

  function onClick() {
    async function doOnClick() {
      setState('loading');

      const { accessToken } = (await fetchAuthSession()).tokens ?? {};

      if (accessToken === undefined) {
        setState('error');
        return;
      }

      const accessTokenString = accessToken.toString();

      try {
        const result = await fetch(
          `${import.meta.env.VITE_API_BASE_URL}/invoices/csv`,
          {
            body: '',
            headers: {
              authorization: `Bearer ${accessTokenString}`,
            },
            method: 'POST',
          },
        );

        if (result.status !== 200) {
          throw new Error();
        }

        setState('success');
      } catch (err) {
        setState('error');
      }
    }

    void doOnClick();
  }

  return (
    <Authenticator components={authenticatorComponents} hideSignUp>
      <div className="p-8">
        <div className="flex flex-col items-start gap-y-2">
          <div>Proxima Internal App</div>
          <button
            className="bg-purple-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={onClick}
            type="button"
          >
            Generate Invoice CSV
          </button>
          <div>{state}</div>
        </div>
      </div>
    </Authenticator>
  );
}
